/*
  Diese Komponente verwaltet die Umfrage und deren Daten. Sie überwacht den Fortschritt der Umfrage,
  verwaltet den Seitenwechsel und aktualisiert die Anzeige je nach Benutzereingabe.
*/
import './SurveyWrapper.scss'; // Importieren von Styles für die Umfragekomponente
import 'survey-core/defaultV2.min.css'; // Importieren von Standard-Stilen für die Umfrage
import 'survey-core/i18n/german'; // Importieren der deutschen Übersetzungen für die Umfrage

// Importieren von React-Hooks und Komponenten
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Survey } from 'survey-react-ui'; // Importieren der Survey-Komponente
import { Model } from 'survey-core'; // Importieren des Survey-Modells
import { surveyJSON } from '../../data/data'; // Importieren der Umfrage-Daten
import PercentageProgressBar from './PercentageProgressBar'; // eslint-disable-line
import { sendData } from '../../services/api'; // Importieren der Funktion zum Senden von Daten an die API

/*
  Diese Funktion umgibt die Umfrage und steuert deren Verhalten und Anzeige.
*/
const SurveyWrapper = ({ onCompleteSurvey, onChangeTableData, onPageChanging, fetchedSurveyData }) => {
  // Zustandsvariablen für Umfragedaten und Umfrageobjekt
  const [surveyData, setSurveyData] = useState([]);
  const survey = new Model(surveyJSON);

  // Variable zur Verwaltung der aktuellen Umfrageseite und Animationseinstellungen
  let currentPage = 0;
  let doAnimation = true;
  let direction = 'right';
  let activeAnswerAndRowPerQuestion = useRef([]);
  let isScrolling = false;

  // Effekt: Initialisierung der Umfragedaten und Positionierung des Bildlaufs
  useEffect(() => {
    if (fetchedSurveyData.length > 0) {
      activeAnswerAndRowPerQuestion.current = fetchedSurveyData[1];
      survey.data = fetchedSurveyData[0];
    }
  }, [fetchedSurveyData]); // eslint-disable-line

  // Funktion: Überprüfung und Anzeige von benutzerdefinierten Navigationssteuerelementen
  const checkHideDefaultNavigationOnHtmlContentAndSetCustomButton = () => {
    // Überprüfung, ob benutzerdefinierte Navigationssteuerelemente vorhanden sind
    const exists = document.getElementsByClassName('hide-default-navigation');

    if (exists.length !== 0) {
      // Entfernen der Standardnavigation
      document.getElementsByClassName('sd-action-bar')[0].classList.add('hide');

      // Hinzufügen eines benutzerdefinierten Navigationsbuttons
      document.getElementById('sd-custom-button').onclick = () => {
        survey.nextPage();
      };
    } else {
      // Standardnavigation anzeigen
      document.getElementsByClassName('sd-action-bar')[0].classList.remove('hide');
    }
  };

  // Funktion: Änderung der Daten der festen Tabelle
  const changeFixedTableData = (name, data) => {
    onChangeTableData({ name: name, columns: data });
  };

  // Funktion: Animation anzeigen
  const animate = (isShowing) => {
    showSurvey();
    const element = document.getElementById('survey');
    if (!!element) {
      const classes = element.classList;
      classes.remove('hidden-left');
      classes.remove('hidden-right');
      classes.remove('visible-left');
      classes.remove('visible-right');
      if (!isShowing) {
        if (!classes.contains(direction === 'right' ? 'hidden-right' : 'hidden-left')) {
          classes.add(direction === 'right' ? 'hidden-right' : 'hidden-left');
        }
      } else {
        isShowing ? classes.add(direction === 'right' ? 'visible-right' : 'visible-left') : classes.remove(direction === 'right' ? 'visible-right' : 'visible-left');
      }
    }
  };

  // Funktion: Umfrage ausblenden
  const hideSurvey = () => {
    const element = document.getElementById('survey');
    element.classList.add('hidden');
  };

  // Funktion: Umfrage anzeigen
  const showSurvey = () => {
    const element = document.getElementById('survey');
    element.classList.remove('hidden');
  };

  // Funktion: Bildlaufposition setzen
  const scrollToPosition = (position, time) => {
    if (isScrolling) return;

    console.log('scrollToPosition isScrolling');
    isScrolling = true;

    if (window.scrollY === position) return;
    setTimeout(() => {
      window.scrollTo({ top: position, behavior: 'smooth' });
    }, time);

    setTimeout(() => {
      isScrolling = false;
    }, 500);
  };

  // Funktion: Fortschrittsleiste setzen
  const setProgressBar = () => {
    const progressBarElement = document.getElementById('progressbar');
    const newProgressBarElementPosition = document.getElementById('balance');
    progressBarElement.parentNode.removeChild(progressBarElement);
    newProgressBarElementPosition.insertBefore(progressBarElement, newProgressBarElementPosition.firstChild);
    progressBarElement.classList.add('visible');
  };

  // Funktion: Fortschrittsleiste entfernen
  const removeProgressBar = () => {
    const progressBarElement = document.getElementById('progressbar');
    progressBarElement.classList.remove('visible');
  };

  // Funktion: Nächsten Button setzen
  const setNextButton = (visible, time = 0) => {
    setTimeout(() => {
      const nextButton = document.getElementsByClassName('sd-navigation__next-btn')[0];
      const completeButton = document.getElementsByClassName('sd-navigation__complete-btn')[0];

      if (visible) {
        if (nextButton) nextButton.disabled = false;
        if (completeButton) completeButton.disabled = false;
      } else {
        if (nextButton) nextButton.disabled = true;
        if (completeButton) completeButton.disabled = true;
      }
    }, time);
  };

  // Funktion: Fußzeilenbeschreibung setzen
  const setFooterDescription = () => {
    const footer = document.getElementsByClassName('sd-footer')[0];
    let description = document.getElementsByClassName('sd-footer-description')[0];
    if (!description) {
      description = document.createElement('p');
      description.classList.add('sd-footer-description');
      description.innerHTML = "Bitte alle Fragen beantworten, dann geht's weiter ...";
      if (footer) footer.appendChild(description);
    } else {
      description.classList.remove('hide');
    }
  };

  // Funktion: Fußzeilenbeschreibung ausblenden
  const hideFooterDescription = () => {
    let description = document.getElementsByClassName('sd-footer-description')[0];
    description.classList.add('hide');
  };

  // Funktion: Überprüfung der Validierung
  const handleCheckValidation = (validation = false) => {
    setTimeout(() => {
      const tables = document.getElementsByClassName('sd-table__row');

      if (tables) {
        let errorInRow = false;
        let noErrors = [];

        Array.from(tables).forEach((table, index) => {
          if (errorInRow) return;

          let exists = false;

          const tableData = table.getElementsByClassName('sd-matrix__cell');

          Array.from(tableData).forEach((data) => {
            const elements = data.getElementsByClassName('sd-matrix__label');

            Array.from(elements).forEach((element) => {
              element.classList.remove('validation-error-checked');
              if (element.classList.contains('sd-radio--checked')) {
                exists = true;
                return;
              }
            });
          });

          if (!exists && validation) {
            errorInRow = true;
            console.log('handleCheckValidation scrollToPosition');
            scrollToPosition(tables[index].getBoundingClientRect().top + (window.scrollY - 100), 100);
            Array.from(tableData).forEach((data, index) => {
              const elements = data.getElementsByClassName('sd-matrix__label');

              Array.from(elements).forEach((element) => {
                if (index === 1) {
                  element.classList.add('validation-error-checked');
                  return;
                }
              });
            });
          }
          if (exists) {
            noErrors.push('true');
          }
        });

        if (noErrors.length !== tables.length) {
          setNextButton(false);
        } else {
          setNextButton(true);
        }
      }
    }, 100);
  };

  // Umfragekonfiguration
  survey.locale = 'de';

  // Benutzerdefinierte Fehlermeldung
  survey.onErrorCustomText.add((survey, options) => {
    options.text = 'Bitte beantworte alle Fragen.';
  });

  // Hinzufügen der Prozentualfortschrittsleiste
  survey.addLayoutElement({
    id: 'progressbar-percentage',
    component: 'sv-progressbar-percentage',
    container: 'header',
    data: survey
  });

  // Anwenden des Themes und der Farbpalette
  survey.applyTheme({
    cssVariables: {
      '--font-family': 'var(--font-family)',
      '--sjs-font-questiontitle-weight': 'var(--font-weight)',
      '--primary': 'var(--tertiary-color)',
      '--red-light': 'var(--error-background-color)',
      '--red': 'var(--error-color)',
      '--sjs-primary-backcolor-dark': 'var(--secondary-color)'
    },
    themeName: 'default_exported',
    colorPalette: 'light',
    isPanelless: false
  });

  survey.onValidatedErrorsOnCurrentPage.add(() => {
    handleCheckValidation(true); // Überprüfung der Validierung bei Fehlern auf der aktuellen Seite
  });

  survey.questionErrorLocation = 'bottom'; // Festlegen des Ortes für die Anzeige von Fragefehlern

  survey.onAfterRenderQuestion.add((survey, option) => {
    onPageChanging(false); // Verhindern von Seitenwechseln während des Renderns der Frage
    console.log(option.question); // Protokollierung der gerenderten Frage
    setTimeout(() => {
      if (option.question) changeFixedTableData(option.question.jsonObj.name, option.question.jsonObj.columns); // Aktualisierung der festen Tabelle nach dem Rendern der Frage
    }, 1000);

    checkHideDefaultNavigationOnHtmlContentAndSetCustomButton(); // Überprüfung und Anpassung der Navigationssteuerelemente
  });

  survey.onCurrentPageChanging.add(function (survey, options) {
    onPageChanging(true); // Signalisierung des Seitenwechsels
    options.isNextPage ? (direction = 'right') : (direction = 'left'); // Bestimmung der Richtung des Seitenwechsels

    if (!doAnimation) return;
    options.allow = false;
    setTimeout(function () {
      // Verzögerung des Seitenwechsels und Animation
      doAnimation = false;
      survey.currentPage = options.newCurrentPage;
      doAnimation = true;
      if (options.newCurrentPage.propertyHash.num === 1) {
        setNextButton(true); // Anzeigen des nächsten Buttons auf der ersten Seite
      } else {
        setNextButton(false); // Ausblenden des nächsten Buttons auf anderen Seiten
      }
    }, 280);

    animate(false); // Ausführen der Animation beim Seitenwechsel
  });

  survey.onCurrentPageChanged.add((survey, options) => {
    currentPage = options.newCurrentPage.propertyHash.num; // Aktualisierung der aktuellen Seitennummer

    if (options.newCurrentPage.propertyHash.num > 1) {
      setFooterDescription(); // Anzeigen der Fußzeilenbeschreibung
      setProgressBar(); // Anzeigen der Fortschrittsleiste
      setFooterDescription(); // Erneutes Anzeigen der Fußzeilenbeschreibung
      setNextQuestionVisible(500); // Anzeigen der nächsten Frage
    } else {
      hideFooterDescription(); // Ausblenden der Fußzeilenbeschreibung
      setNextButton(true, 500); // Anzeigen des nächsten Buttons auf der ersten Seite
      removeProgressBar(); // Ausblenden der Fortschrittsleiste auf der ersten Seite
    }

    hideSurvey(); // Ausblenden der Umfrage
    setTimeout(() => {
      animate(true); // Ausführen der Animation nach dem Seitenwechsel
    }, 10);
  });

  survey.onComplete.add((survey, options) => {
    if (!doAnimation) return;

    doAnimation = false;

    const newData = survey.data;

    setSurveyData((prevData) => [...prevData, newData]); // Aktualisieren der Umfragedaten

    if (survey.currentPageNo < survey.visiblePages.length - 1) {
      survey.nextPage(); // Navigieren zur nächsten Seite
    } else {
      onCompleteSurvey([...surveyData, newData]); // Abschluss der Umfrage
      setNextButton(false); // Ausblenden des nächsten Buttons
      const ProgressBarElement = document.getElementById('progressbar');
      ProgressBarElement.parentNode.removeChild(ProgressBarElement); // Entfernen der Fortschrittsleiste
    }
    doAnimation = true;
  });

  survey.onValueChanged.add((survey, options) => {
    handleCheckValidation(); // Überprüfung der Validierung bei Wertänderung
    let selectedKey = '';
    let currentQuestionNumber = 0;
    Object.keys(options.value).forEach(function (key) {
      selectedKey = key;
    });
    options.question.jsonObj.rows.forEach((row, index) => {
      if (row.value === selectedKey) {
        currentQuestionNumber = index;
        checkNextQuestionToVisible(currentQuestionNumber, survey.data); // Überprüfung der nächsten sichtbaren Frage
      }
    });
  });

  survey.onValidatedErrorsOnCurrentPage.add((survey, options) => {
    setNextQuestionVisible(0); // Anzeigen der nächsten Frage bei Validierungsfehlern auf der aktuellen Seite
  });

  // Funktion zur Speicherung der Umfragedaten
  const saveSurveyData = async (surveyData, activeAnswerAndRowPerQuestion) => {
    const data = [surveyData, activeAnswerAndRowPerQuestion];

    const response = await sendData('surveyData', JSON.stringify(data), 'sendState'); // Senden der Daten an die API

    if (response.success) {
      console.log(response); // Protokollierung des API-Antwortstatus
    } else {
      console.log(response); // Protokollierung des API-Antwortstatus im Fehlerfall
    }
  };

  // Funktion zur Überprüfung und Anzeige der nächsten sichtbaren Frage
  const checkNextQuestionToVisible = (questionNumber, data) => {
    let answer = null;
    Object.keys(data).forEach((key) => {
      var value = data[key];
      Object.keys(value).forEach((number, index) => {
        if (index === Object.keys(value).length - 1) {
          answer = value[number];
        }
      });
    });

    let question = {
      row: questionNumber,
      answer: answer
    };

    if (!activeAnswerAndRowPerQuestion.current[currentPage - 2]) {
      const entry = [];
      const newEntry = [];
      newEntry.push(question);
      entry.push(questionNumber);
      activeAnswerAndRowPerQuestion.current[currentPage - 2] = newEntry;
    } else {
      const exists = activeAnswerAndRowPerQuestion.current[currentPage - 2].some((objekt) => objekt.row === questionNumber);

      if (exists) {
        activeAnswerAndRowPerQuestion.current[currentPage - 2] = activeAnswerAndRowPerQuestion.current[currentPage - 2].map((objekt) => (objekt.row === questionNumber ? (objekt.answer = question) : objekt));
      } else {
        activeAnswerAndRowPerQuestion.current[currentPage - 2] = [...activeAnswerAndRowPerQuestion.current[currentPage - 2], { row: questionNumber, answer: answer }];
      }
    }

    saveSurveyData(survey.data, activeAnswerAndRowPerQuestion.current);
    const tables = document.getElementsByClassName('sd-table__row');

    setTimeout(() => {
      if (questionNumber < tables.length - 2) {
        const nextTablePosition = tables[questionNumber + 1].getBoundingClientRect().top;
        const nextTableHeight = tables[questionNumber + 1].offsetHeight;
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;

        if (nextTablePosition + nextTableHeight + scrollPosition + 100 > windowHeight) {
          console.log('getBoundingClientRect scrollToPosition');
          scrollToPosition(tables[questionNumber + 1].getBoundingClientRect().top + (window.scrollY - 100), 100);
        }
      } else {
        scrollToPosition(3000, 100);
      }
    }, 100);

    setNextQuestionVisible(0);
  };

  // Funktion zur Anzeige der nächsten sichtbaren Frage
  const setNextQuestionVisible = (time) => {
    setTimeout(() => {
      const tables = document.getElementsByClassName('sd-table__row');

      if (activeAnswerAndRowPerQuestion.current[currentPage - 2] && tables.length > 0) {
        activeAnswerAndRowPerQuestion.current[currentPage - 2].forEach((question, index) => {
          tables[question.row].classList.add('visible');
          if (question.row + 1 < tables.length) {
            tables[question.row + 1].classList.add('visible');
          }
        });
      }

      handleCheckValidation();
    }, time);
  };

  // Erstellung der Umfragekomponente
  const surveyComponent = useMemo(() => <Survey model={survey} />, []); // eslint-disable-line

  return surveyComponent; // Rückgabe der Umfragekomponent
};

export default SurveyWrapper;
