/*
  Diese Komponente stellt den Footer der Anwendung dar.
  Sie enthält den Copyright-Text mit dem aktuellen Jahr.
*/

import './Footer.scss';
import React from 'react';

// Definition der Footer-Komponente als Funktionskomponente
const FooterComponent = () => {
  return (
    <div className='footer'>
      {' '}
      {/* Container für den Footer mit CSS-Klasse */}
      <p className='footer-text'>© AMBION {new Date().getFullYear()}</p> {/* Copyright-Text mit aktuellem Jahr */}
    </div>
  );
};

export default FooterComponent; // Export der Footer-Komponente für die Verwendung in anderen Dateien
