/*
  Diese Komponente repräsentiert den Header für das Diagramm.
  Es enthält Informationen über den Balance-Stern und Legenden für das Diagramm.
*/

import './ChartHeader.scss';
import React from 'react';
import iconReal from '../../assets/images/real_icon_legend.svg'; // Importieren des Icons für "REAL"
import iconWunsch from '../../assets/images/wunsch_icon_legend.svg'; // Importieren des Icons für "WUNSCH"

// Definition der ChartHeader-Komponente als Funktionskomponente
const ChartHeaderComponent = ({ onPrintChart, onOpenOverlay }) => {
  // Funktion zum Anzeigen/Verbergen des Overlay-Infos
  const handleInfoOverlay = () => {
    onOpenOverlay();
    //setOverlayIsOpen(!overlayIsOpen);
  };

  const handlePrint = () => {
    onPrintChart();
    //printComponent();
  };

  return (
    <div id='chart-header' className='chart-header'>
      <h1>Dein Balance-Stern </h1>
      <p className='subtitle'>Vielen Dank, dass Du am Balance-Test teilgenommen hast. Und hier ist Dein berechnetes Ergebnis in Form Deines persönlichen Balance-Sterns. Was der Stern genau bedeutet erfährst Du hier. Drucke ihn Dir aus oder speichere ihn als PDF. Wenn Du magst, kannst Du ihn als Gesprächsgrundlage für das nächste Gespräch mit Deiner Führungskraft nutzen.</p>
      <div className='legend-container'>
        <div className='legends'>
          <div className='legend'>
            <div className='icon'>
              <img src={iconReal} alt='Balance-Test' />
            </div>
            <p>REAL</p>
          </div>
          <div className='legend second'>
            <div className='icon'>
              <img src={iconWunsch} alt='Balance-Test' />
            </div>
            <p>WUNSCH</p>
          </div>
        </div>
        <div className='button-container'>
          <button className='button' onClick={handleInfoOverlay}>
            INFO Balance-Stern
          </button>
          <button className='button' onClick={handlePrint}>
            Drucken / Speichern
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChartHeaderComponent; // Export der ChartHeader-Komponente für die Verwendung in anderen Dateien
