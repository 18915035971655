/*
  Diese Komponente repräsentiert die Balance-Seite der Anwendung.
  Sie steuert den Wechsel zwischen dem Survey, der Chart-Ansicht und der Abschlusskomponente.
*/
import './Balance.scss';
import React, { useState } from 'react';
import SurveyComponent from '../Survey/Survey'; // Importieren der SurveyComponent-Komponente
import ChartComponent from '../Chart/Chart'; // Importieren der ChartComponent-Komponente
import FinishComponent from '../Finish/Finish'; // Importieren der FinishComponent-Komponente

const BalanceComponent = ({ data }) => {
  // Zustandsvariablen für die Chart-Ansicht, Survey-Daten und Kategorien sowie die Abschlussanzeige
  const [showChart, setShowChart] = useState(false);
  const [surveyData, setSurveyData] = useState();
  const [surveyCategories, setSurveyCategories] = useState();
  const [showFinal, setShowFinal] = useState(false);

  // Funktion: Behandlung des Survey-Abschlusses
  const handleSurveyComplete = (data, categories) => {
    setSurveyCategories(categories);
    setSurveyData(data);
    onSurveyComplete();
  };

  // Funktion: Behandlung des Chart-Abschlusses
  const handleChartComplete = () => {
    onChartComplete();
  };

  // Funktion: Chart-Abschluss
  const onChartComplete = () => {
    setShowFinal(true);
  };

  // Funktion: Survey-Abschluss
  const onSurveyComplete = () => {
    setShowChart(true);
  };

  // Rückgabe der JSX-Komponente
  return (
    <div id='balance'>
      {/* Entscheidung über die Darstellung der Survey-, Chart- oder Abschlusskomponente */}
      {!showFinal ? !showChart ? <SurveyComponent onCompleteSurvey={handleSurveyComplete} /> : <ChartComponent data={[surveyData, data.code]} categories={surveyCategories} onCompleteChart={handleChartComplete} /> : <FinishComponent />}
    </div>
  );
};

export default BalanceComponent;
