/*
  Diese Komponente repräsentiert die abgelaufene Umfrage.
  Sie wird angezeigt, wenn die Umfrage abgelaufen ist.
*/

import './Expired.scss';
import React from 'react';

// Definition der Expired-Komponente als Funktionskomponente
const ExpiredComponent = ({ expireDate }) => {
  console.log(expireDate);
  return (
    <div id='expired'>
      {/* Container für die abgelaufene Umfrage mit ID */}
      <p>
        Der AMBION Balance Test
        <br />
        ist beendet.
      </p>{' '}
      {/* Hinweis auf das Ablaufdatum der Umfrage */}
    </div>
  );
};

export default ExpiredComponent; // Export der Expired-Komponente für die Verwendung in anderen Dateien
