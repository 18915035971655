import video from '../assets/videos/Video_Balance_final.mp4';

export const surveyJSON = {
  checkErrorsMode: 'onValueChanged',
  progressTitle: 'Fortschritt',
  progressBarType: 'questions',
  pages: [
    {
      questions: [
        {
          type: 'html',
          html: `<div class="html-content hide-default-navigation"><div class="content"><div class="video-content"><video width="1920" height="1080" controls autoplay playsinline disableremoteplayback><source src="${video}" type="video/mp4">Your browser does not support the video tag.</video></div><div class="description-content"><div class="description"><p>Liebe/r AMBIONAUT/IN,</p><p>der AMBION Balance-Test macht die Stressfaktoren und Ressourcen Deiner Arbeitsbedingungen sichtbar. Beantworte bitte die folgenden Fragen und Aussagen zu Deinen Arbeitsbedingungen auf den nächsten Seiten.</p><p>Jede Frage mussst Du zweimal beantworten. Einmal so, wie Du Deine reale Arbeitssituation aktuell siehst und einmal so, wie Du Dir Deine zukünftige Arbeitssituation wünschst.</p><p>Bitte beantworte alle Fragen, denn sonst kann der Test nicht ausgewertet werden. Deine persönliche Einschätzung ist entscheidend.</p><p>Der AMBION Balance-Test ist anonym, Deine Antworten werden nicht zu Deiner Person gespeichert. Und zum Schluss erhältst Du eine Auswertung in Form des Balance-Sterns.</p><p>Vielen Dank für Deine Mitarbeit!</p></div><div class="navigation"><div class="button-container"><button id="sd-custom-button" class="sd-btn sd-navigation__next-btn">Balance-Test starten</button></div></div></div></div></div>`
        }
      ]
    },
    {
      questions: [
        {
          type: 'matrix',
          name: 'A/ HANDLUNGSSPIELRAUM',
          title: 'A/ HANDLUNGSSPIELRAUM',

          columns: [
            {
              value: 1,
              text: 'sehr wenig'
            },
            {
              value: 2,
              text: 'ziemlich wenig'
            },
            {
              value: 3,
              text: 'etwas'
            },
            {
              value: 4,
              text: 'ziemlich viel'
            },
            {
              value: 5,
              text: 'sehr viel'
            }
          ],
          rows: [
            {
              value: 'Wenn Du Deine Tätigkeit insgesamt betrachtest, inwieweit kannst Du die Reihenfolge der Arbeitsschritte selbst bestimmen? (REAL)',
              text: 'Wenn Du Deine Tätigkeit insgesamt betrachtest, inwieweit kannst Du die Reihenfolge der Arbeitsschritte selbst bestimmen?'
            },
            {
              value: 'Wenn Du Deine Tätigkeit insgesamt betrachtest, inwieweit kannst Du die Reihenfolge der Arbeitsschritte selbst bestimmen? (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'Wieviel Einfluss hast Du darauf, welche Arbeit Dir zugeteilt wird? (REAL)',
              text: 'Wieviel Einfluss hast Du darauf, welche Arbeit Dir zugeteilt wird?'
            },
            {
              value: 'Wieviel Einfluss hast Du darauf, welche Arbeit Dir zugeteilt wird? (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'Kannst Du Deine Arbeit selbstständig planen und einteilen? (REAL)',
              text: 'Kannst Du Deine Arbeit selbstständig planen und einteilen?'
            },
            {
              value: 'Kannst Du Deine Arbeit selbstständig planen und einteilen? (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            }
          ],
          alternateRows: true,
          isAllRowRequired: true
        }
      ]
    },
    {
      questions: [
        {
          type: 'matrix',
          name: 'B/ VIELSEITIGES ARBEITEN',
          title: 'B/ VIELSEITIGES ARBEITEN',
          columns: [
            {
              value: 1,
              text: 'trifft gar nicht zu'
            },
            {
              value: 2,
              text: 'trifft wenig zu'
            },
            {
              value: 3,
              text: 'trifft mittelmäßig zu'
            },
            {
              value: 4,
              text: 'trifft überwiegend zu'
            },
            {
              value: 5,
              text: 'trifft völlig zu'
            }
          ],
          rows: [
            {
              value: 'Kannst Du bei Deiner Arbeit Neues dazulernen? (REAL)',
              text: 'Kannst Du bei Deiner Arbeit Neues dazulernen?'
            },
            {
              value: 'Kannst Du bei Deiner Arbeit Neues dazulernen? (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'Kannst Du bei Deiner Arbeit Wissen und Können voll einsetzen? (REAL)',
              text: 'Kannst Du bei Deiner Arbeit Wissen und Können voll einsetzen?'
            },
            {
              value: 'Kannst Du bei Deiner Arbeit Wissen und Können voll einsetzen? (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'Bei meiner Arbeit habe ich insgesamt gesehen häufig wechselnde, unterschiedliche Arbeitsaufgaben. (REAL)',
              text: 'Bei meiner Arbeit habe ich insgesamt gesehen häufig wechselnde, unterschiedliche Arbeitsaufgaben.'
            },
            {
              value: 'Bei meiner Arbeit habe ich insgesamt gesehen häufig wechselnde, unterschiedliche Arbeitsaufgaben. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            }
          ],
          alternateRows: true,
          isAllRowRequired: true
        }
      ]
    },
    {
      questions: [
        {
          type: 'matrix',
          name: 'C/ GANZHEITLICHES ARBEITEN',
          title: 'C/ GANZHEITLICHES ARBEITEN',
          columns: [
            {
              value: 1,
              text: 'trifft gar nicht zu'
            },
            {
              value: 2,
              text: 'trifft wenig zu'
            },
            {
              value: 3,
              text: 'trifft mittelmäßig zu'
            },
            {
              value: 4,
              text: 'trifft überwiegend zu'
            },
            {
              value: 5,
              text: 'trifft völlig zu'
            }
          ],
          rows: [
            {
              value: 'Bei meiner Arbeit sehe ich selber am Ergebnis, ob meine Arbeit gut war oder nicht. (REAL)',
              text: 'Bei meiner Arbeit sehe ich selber am Ergebnis, ob meine Arbeit gut war oder nicht.'
            },
            {
              value: 'Bei meiner Arbeit sehe ich selber am Ergebnis, ob meine Arbeit gut war oder nicht. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'Meine Arbeit ist so gestaltet, dass ich die Möglichkeit habe, ein vollständiges Arbeitsprodukt von Anfang bis Ende herzustellen. (REAL)',
              text: 'Meine Arbeit ist so gestaltet, dass ich die Möglichkeit habe, ein vollständiges Arbeitsprodukt von Anfang bis Ende herzustellen.'
            },
            {
              value: 'Meine Arbeit ist so gestaltet, dass ich die Möglichkeit habe, ein vollständiges Arbeitsprodukt von Anfang bis Ende herzustellen. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            }
          ],
          alternateRows: true,
          isAllRowRequired: true
        }
      ]
    },
    {
      questions: [
        {
          type: 'matrix',
          name: 'D/ SOZIALE RÜCKENDECKUNG',
          title: 'D/ SOZIALE RÜCKENDECKUNG',
          customAttribute: 'question-set-1',
          columns: [
            {
              value: 1,
              text: 'trifft gar nicht zu'
            },
            {
              value: 2,
              text: 'trifft wenig zu'
            },
            {
              value: 3,
              text: 'trifft mittelmäßig zu'
            },
            {
              value: 4,
              text: 'trifft überwiegend zu'
            },
            {
              value: 5,
              text: 'trifft völlig zu'
            }
          ],
          rows: [
            {
              value: 'Ich kann mich auf meine Kolleginnen und Kollegen verlassen, wenn es bei der Arbeit schwierig wird. (REAL)',
              text: 'Ich kann mich auf meine Kolleginnen und Kollegen verlassen, wenn es bei der Arbeit schwierig wird.'
            },
            {
              value: 'Ich kann mich auf meine Kolleginnen und Kollegen verlassen, wenn es bei der Arbeit schwierig wird. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'Ich kann mich auf meinen direkten Vorgesetzten verlassen, wenn es bei der Arbeit schwierig wird. (REAL)',
              text: 'Ich kann mich auf meinen direkten Vorgesetzten verlassen, wenn es bei der Arbeit schwierig wird.'
            },
            {
              value: 'Ich kann mich auf meinen direkten Vorgesetzten verlassen, wenn es bei der Arbeit schwierig wird. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'Wir halten in der Abteilung gut zusammen. (REAL)',
              text: 'Wir halten in der Abteilung gut zusammen.'
            },
            {
              value: 'Wir halten in der Abteilung gut zusammen (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            }
          ],
          alternateRows: true,
          isAllRowRequired: true
        }
      ]
    },
    {
      questions: [
        {
          type: 'matrix',
          name: 'E/ ZUSAMMENARBEIT',
          title: 'E/ ZUSAMMENARBEIT',
          columns: [
            {
              value: 1,
              text: 'trifft gar nicht zu'
            },
            {
              value: 2,
              text: 'trifft wenig zu'
            },
            {
              value: 3,
              text: 'trifft mittelmäßig zu'
            },
            {
              value: 4,
              text: 'trifft überwiegend zu'
            },
            {
              value: 5,
              text: 'trifft völlig zu'
            }
          ],
          rows: [
            {
              value: 'Meine Arbeit erfordert eine enge Zusammenarbeit mit anderen Kolleginnen und Kollegen im Unternehmen. (REAL)',
              text: 'Meine Arbeit erfordert eine enge Zusammenarbeit mit anderen Kolleginnen und Kollegen im Unternehmen.'
            },
            {
              value: 'Meine Arbeit erfordert eine enge Zusammenarbeit mit anderen Kolleginnen und Kollegen im Unternehmen. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'Ich kann mich während der Arbeit mit verschiedenen Kolleginnen und Kollegen über dienstliche und private Dinge unterhalten. (REAL)',
              text: 'Ich kann mich während der Arbeit mit verschiedenen Kolleginnen und Kollegen über dienstliche und private Dinge unterhalten.'
            },
            {
              value: 'Ich kann mich während der Arbeit mit verschiedenen Kolleginnen und Kollegen über dienstliche und private Dinge unterhalten. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'Ich bekomme von Vorgesetzten, Kolleginnen und Kollegen immer Rückmeldung über die Qualität meiner Arbeit. (REAL)',
              text: 'Ich bekomme von Vorgesetzten, Kolleginnen und Kollegen immer Rückmeldung über die Qualität meiner Arbeit.'
            },
            {
              value: 'Ich bekomme von Vorgesetzten, Kolleginnen und Kollegen immer Rückmeldung über die Qualität meiner Arbeit. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            }
          ],
          alternateRows: true,
          isAllRowRequired: true
        }
      ]
    },
    {
      questions: [
        {
          type: 'matrix',
          name: 'F/ PASSENDE INHALTLICHE ARBEITSANFORDERUNGEN',
          title: 'F/ PASSENDE INHALTLICHE ARBEITSANFORDERUNGEN',
          columns: [
            {
              value: 5,
              text: 'trifft gar nicht zu'
            },
            {
              value: 4,
              text: 'trifft wenig zu'
            },
            {
              value: 3,
              text: 'trifft mittelmäßig zu'
            },
            {
              value: 2,
              text: 'trifft überwiegend zu'
            },
            {
              value: 1,
              text: 'trifft völlig zu'
            }
          ],
          rows: [
            {
              value: 'Bei meiner Arbeit gibt es Sachen, die zu kompliziert sind. (REAL)',
              text: 'Bei meiner Arbeit gibt es Sachen, die zu kompliziert sind.'
            },
            {
              value: 'Bei meiner Arbeit gibt es Sachen, die zu kompliziert sind. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'Es werden zu hohe Anforderungen an meine Konzentrationsfähigkeit gestellt. (REAL)',
              text: 'Es werden zu hohe Anforderungen an meine Konzentrationsfähigkeit gestellt.'
            },
            {
              value: 'Es werden zu hohe Anforderungen an meine Konzentrationsfähigkeit gestellt. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            }
          ],
          alternateRows: true,
          isAllRowRequired: true
        }
      ]
    },
    {
      questions: [
        {
          type: 'matrix',
          name: 'G/ PASSENDE MENGENMÄSSIGE ARBEIT',
          title: 'G/ PASSENDE MENGENMÄSSIGE ARBEIT',
          columns: [
            {
              value: 5,
              text: 'trifft gar nicht zu'
            },
            {
              value: 4,
              text: 'trifft wenig zu'
            },
            {
              value: 3,
              text: 'trifft mittelmäßig zu'
            },
            {
              value: 2,
              text: 'trifft überwiegend zu'
            },
            {
              value: 1,
              text: 'trifft völlig zu'
            }
          ],
          rows: [
            {
              value: 'Ich stehe häufig unter Zeitdruck. (REAL)',
              text: 'Ich stehe häufig unter Zeitdruck.'
            },
            {
              value: 'Ich stehe häufig unter Zeitdruck. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'Ich habe zuviel Arbeit. (REAL)',
              text: 'Ich habe zuviel Arbeit.'
            },
            {
              value: 'Ich habe zuviel Arbeit. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            }
          ],
          alternateRows: true,
          isAllRowRequired: true
        }
      ]
    },
    {
      questions: [
        {
          type: 'matrix',
          name: 'H/ PASSENDE ARBEITSABLÄUFE',
          title: 'H/ PASSENDE ARBEITSABLÄUFE',
          columns: [
            {
              value: 5,
              text: 'trifft gar nicht zu'
            },
            {
              value: 4,
              text: 'trifft wenig zu'
            },
            {
              value: 3,
              text: 'trifft mittelmäßig zu'
            },
            {
              value: 2,
              text: 'trifft überwiegend zu'
            },
            {
              value: 1,
              text: 'trifft völlig zu'
            }
          ],
          rows: [
            {
              value: 'Oft stehen mir die benötigten Informationen, Materialien und Arbeitsmittel (z.B. Computer, Werkzeug, ...) nicht zur Verfügung. (REAL)',
              text: 'Oft stehen mir die benötigten Informationen, Materialien und Arbeitsmittel (z.B. Computer, Werkzeug, ...) nicht zur Verfügung.'
            },
            {
              value: 'Oft stehen mir die benötigten Informationen, Materialien und Arbeitsmittel (z.B. Computer, Werkzeug, ...) nicht zur Verfügung. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'Ich werde bei meiner eigentlichen Arbeit immer wieder unterbrochen (z.B. durch das Telefon). (REAL)',
              text: 'Ich werde bei meiner eigentlichen Arbeit immer wieder unterbrochen (z.B. durch das Telefon).'
            },
            {
              value: 'Ich werde bei meiner eigentlichen Arbeit immer wieder unterbrochen (z.B. durch das Telefon). (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            }
          ],
          alternateRows: true,
          isAllRowRequired: true
        }
      ]
    },
    {
      questions: [
        {
          type: 'matrix',
          name: 'I/ PASSENDE ARBEITSUMGEBUNG',
          title: 'I/ PASSENDE ARBEITSUMGEBUNG',
          columns: [
            {
              value: 5,
              text: 'trifft gar nicht zu'
            },
            {
              value: 4,
              text: 'trifft wenig zu'
            },
            {
              value: 3,
              text: 'trifft mittelmäßig zu'
            },
            {
              value: 2,
              text: 'trifft überwiegend zu'
            },
            {
              value: 1,
              text: 'trifft völlig zu'
            }
          ],
          rows: [
            {
              value: 'An meinem Arbeitsplatz gibt es ungünstige Umgebungsbedingungen wie Lärm, Klima, Staub. (REAL)',
              text: 'An meinem Arbeitsplatz gibt es ungünstige Umgebungsbedingungen wie Lärm, Klima, Staub.'
            },
            {
              value: 'An meinem Arbeitsplatz gibt es ungünstige Umgebungsbedingungen wie Lärm, Klima, Staub. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'An meinem Arbeitsplatz sind Räume und Raumausstattung ungenügend. (REAL)',
              text: 'An meinem Arbeitsplatz sind Räume und Raumausstattung ungenügend.'
            },
            {
              value: 'An meinem Arbeitsplatz sind Räume und Raumausstattung ungenügend. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            }
          ],
          alternateRows: true,
          isAllRowRequired: true
        }
      ]
    },
    {
      questions: [
        {
          type: 'matrix',
          name: 'J/ INFORMATION UND MITSPRACHE',
          title: 'J/ INFORMATION UND MITSPRACHE',
          columns: [
            {
              value: 1,
              text: 'trifft gar nicht zu'
            },
            {
              value: 2,
              text: 'trifft wenig zu'
            },
            {
              value: 3,
              text: 'trifft mittelmäßig zu'
            },
            {
              value: 4,
              text: 'trifft überwiegend zu'
            },
            {
              value: 5,
              text: 'trifft völlig zu'
            }
          ],
          rows: [
            {
              value: 'Über wichtige Dinge und Vorgänge in unserem Unternehmen bin ich ausreichend informiert. (REAL)',
              text: 'Über wichtige Dinge und Vorgänge in unserem Unternehmen bin ich ausreichend informiert.'
            },
            {
              value: 'Über wichtige Dinge und Vorgänge in unserem Unternehmen bin ich ausreichend informiert. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'Die Leitung des Unternehmens ist bereit, die Ideen und Vorschläge der Mitarbeiterinnen und Mitarbeiter zu berücksichtigen. (REAL)',
              text: 'Die Leitung des Unternehmens ist bereit, die Ideen und Vorschläge der Mitarbeiterinnen und Mitarbeiter zu berücksichtigen.'
            },
            {
              value: 'Die Leitung des Unternehmens ist bereit, die Ideen und Vorschläge der Mitarbeiterinnen und Mitarbeiter zu berücksichtigen. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            }
          ],
          alternateRows: true,
          isAllRowRequired: true
        }
      ]
    },
    {
      questions: [
        {
          type: 'matrix',
          name: 'K/ ENTWICKLUNGSMÖGLICHKEITEN',
          title: 'K/ ENTWICKLUNGSMÖGLICHKEITEN',
          columns: [
            {
              value: 1,
              text: 'trifft gar nicht zu'
            },
            {
              value: 2,
              text: 'trifft wenig zu'
            },
            {
              value: 3,
              text: 'trifft mittelmäßig zu'
            },
            {
              value: 4,
              text: 'trifft überwiegend zu'
            },
            {
              value: 5,
              text: 'trifft völlig zu'
            }
          ],
          rows: [
            {
              value: 'Unser Unternehmen bietet gute Weiterbildungsmöglichkeiten. (REAL)',
              text: 'Unser Unternehmen bietet gute Weiterbildungsmöglichkeiten.'
            },
            {
              value: 'Unser Unternehmen bietet gute Weiterbildungsmöglichkeiten. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            },
            {
              value: 'Bei uns gibt es gute Aufstiegschancen. (REAL)',
              text: 'Bei uns gibt es gute Aufstiegschancen.'
            },
            {
              value: 'Bei uns gibt es gute Aufstiegschancen. (WUNSCH)',
              text: 'Und wie wäre die ideale Bewertung?'
            }
          ],
          alternateRows: true,
          isAllRowRequired: true
        }
      ]
    }
  ],
  showQuestionNumbers: 'off'
};
