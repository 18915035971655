import './PercentageProgressBar.scss';
import { ReactSurveyElement, ReactElementFactory } from 'survey-react-ui';
import { Serializer } from 'survey-core';
import React from 'react';

Serializer.addProperty('survey', 'progressTitle');

class PercentageProgressBar extends ReactSurveyElement {
  render() {
    return (
      <div className='sv-progressbar-percentage' id='progressbar'>
        <div className='sv-progressbar-percentage__title'>
          <span>
            {this.props.model.progressTitle}: {this.props.model.progressValue + ' %'}
          </span>
        </div>
        <div className='sv-progressbar-percentage__bar-container'>
          <div className='sv-progressbar-percentage__indicator'>
            <div className='sv-progressbar-percentage__value-bar' style={{ width: this.props.model.progressValue + '%' }}></div>
          </div>
        </div>
      </div>
    );
  }
}

ReactElementFactory.Instance.registerElement('sv-progressbar-percentage', (props) => {
  return React.createElement(PercentageProgressBar, props);
});

export default PercentageProgressBar;
