import React from 'react';
import './Pending.scss';
import moment from 'moment'; // Import von Moment.js

const PendingComponent = ({ pendingDate }) => {
  return (
    <div id='pending'>
      {/* Container für die abgelaufene Umfrage mit ID */}

      <p>Der Balance Test wurde gestoppt, da es einen Berechnungsfehler innerhalb des Balance-Sterns gab. Bitte entschuldige dies. Du wirst eine Mail mit einem neuen Zugangscode erhalten.</p>
      {/*<p>
        Der AMBION Balance Test
        <br />
        beginnt am {moment(pendingDate * 1000).format('DD.MM.YYYY')}
    </p>
    */}
    </div>
  );
};

export default PendingComponent;
