/*
  Diese Datei enthält die Implementierung des ChartDescriptionComponents.
  Der ChartDescriptionComponent bietet eine Beschreibung der Bedeutung des Balance-Sterns und gibt konkrete Handlungsempfehlungen.
*/

import './ChartDescription.scss';
import React from 'react';

// Definition der ChartDescriptionComponent-Funktionskomponente
const ChartDescriptionComponent = () => {
  return (
    <div>
      <h2>Was bedeutet Dein Balance-Stern?</h2>
      <span>Ziel des Balance-Tests ist es, Ausgangspunkte für die Reduktion von Stressfaktoren und für die Erweiterung von Ressourcen bei der Arbeit zu erkennen.</span>
      <br />
      <br />
      <span className='bold'>1. Hohe und niedrige Werte</span> <br />
      <span>Höhere Werte (zwischen 3,5 und 5) sind eher positiv und niedrige Werte (zwischen 1 und 2,5) sind eher negativ. Aber nicht alle Menschen finden, dass 5 der beste Wert für sie ist. Daher hat es sich bewährt, vor allem die Abweichungen zwischen den REAL- und WUNSCH-Zahlen zu betrachten.</span>
      <br />
      <br />
      <span className='bold'>2. Große und kleine Abweichungen</span>
      <br />
      <span>
        Je kleiner die Abweichungen zwischen den REAL- und WUNSCH-Zahlen, desto besser passen Deine Arbeitsbedingungen für Dich. Du hast in diesen Bereichen eher Ressourcen und bist eher zufrieden. Große Abweichungen weisen darauf hin, dass die Arbeitsbedingungen nicht gut passen. Du hast in diesem Bereich eher mehr Stressoren und bist unzufriedener. Je besser Realität und Wunsch der
        Arbeitsbedingungen passen, desto eher fördert das Gesundheit und Zufriedenheit.
      </span>
      <h2>Was kannst Du konkret tun?</h2>
      <span>Der Balance-Stern zeigt anhand der Zahlen, welche der 11 Bereiche der Arbeit für Dich besser oder schlechter passen. Überlege, welche konkreten Arbeitsbedingungen hinter Deinen Zahlen stehen. Erst dann können Ideen für Opti- mierungsmaßnahmen konkret entwickelt werden ...</span>
    </div>
  );
};

export default ChartDescriptionComponent;
