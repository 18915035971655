/*
  Diese Komponente stellt den Header der Anwendung dar.
  Sie enthält das Logo und den Markennamen.
*/

import './Header.scss';
import logo from '../../assets/images/ambion_logo.png'; // Importieren des Logos der Anwendung
import brand from '../../assets/images/brand.svg'; // Importieren des Markenbildes der Anwendung

import React from 'react'; // Importieren der React-Bibliothek

// Definition der Header-Komponente als Funktionskomponente
const HeaderComponent = () => {
  return (
    <div id='header' className='header start'>
      {' '}
      {/* Container für den Header mit ID und CSS-Klasse */}
      <img className='brand' alt='AMBION Balance Test' src={brand} /> {/* Bild für die Marke */}
      <img src={logo} alt='AMBION Balance Test' /> {/* Bild für das Logo */}
    </div>
  );
};

export default HeaderComponent; // Export der Header-Komponente für die Verwendung in anderen Dateien
