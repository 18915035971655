/*
  Diese Komponente repräsentiert die Abschlussseite der Umfrage.
  Sie zeigt eine Dankesnachricht an den Benutzer an.
*/

import './Finish.scss';
import React from 'react';

// Definition der Finish-Komponente als Funktionskomponente
const FinishComponent = ({ data }) => {
  return (
    <div className='finish'>
      {' '}
      {/* Container für die Abschlussseite mit CSS-Klasse */}
      <h1>Danke!</h1> {/* Überschrift Dankeschön */}
      <p>Schön, dass du an der AMBION Balance-Umfrage teilgenommen hast.</p> {/* Text zur Dankesnachricht */}
    </div>
  );
};

export default FinishComponent; // Export der Finish-Komponente für die Verwendung in anderen Dateien
