/*
  Diese Komponente stellt das Haupt-Survey-Formular dar.
  Sie ruft Daten ab, handhabt den Zustand der festen Tabelle und koordiniert den Abschluss des Surveys.
*/
import './Survey.scss';
import React, { useEffect, useState } from 'react';
import FixedTable from './FixedTable'; // Importieren der FixedTable-Komponente
import SurveyWrapper from './SurveyWrapper'; // Importieren der SurveyWrapper-Komponente
import { getData } from '../../services/api'; // Importieren der getData-Funktion

const SurveyComponent = ({ onCompleteSurvey }) => {
  // Zustandsvariablen für die festen Tabellendaten, Tabelle ausblenden und abgerufene Survey-Daten
  const [fixedTableData, setFixedTableData] = useState([]);
  const [hideTable, setHideTable] = useState(false);
  const [fetchedSurveyData, setFetchedSurveyData] = useState(null);

  // Effekt: Datenabruf beim Laden der Komponente
  useEffect(() => {
    fetchData();
  }, []);

  // Funktion: Behandlung der festen Tabellendaten
  const handleFixedTableData = (data) => {
    setFixedTableData(data);
  };

  // Funktion: Behandlung des Survey-Abschlusses
  const handleSurveyComplete = (data) => {
    onCompleteSurvey(data);
  };

  // Funktion: Behandlung der Seitenumbrüche
  const handlePageChanging = (value) => {
    setHideTable(value);
  };

  // Funktion: Datenabruf
  const fetchData = async () => {
    const response = await getData('getState');

    if (response.success) {
      setFetchedSurveyData(response.data);
    } else {
      // Fall-back Daten bei Fehler
      console.log('Fehler beim Abrufen der Survey-Daten Fehler: ' + response.message);
      setFetchedSurveyData([]);
    }
  };

  // Rückgabe der JSX-Komponente
  return (
    <div id='survey'>
      {/* Darstellung der festen Tabelle */}
      {<FixedTable data={fixedTableData} hide={hideTable} />}
      {/* Darstellung des Survey-Formulars, sobald die Daten abgerufen wurden */}
      {fetchedSurveyData && <SurveyWrapper fetchedSurveyData={fetchedSurveyData} onChangeTableData={handleFixedTableData} onCompleteSurvey={handleSurveyComplete} onPageChanging={handlePageChanging} />}
    </div>
  );
};

export default SurveyComponent;
