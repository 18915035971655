/*
  Diese Datei dient als Einstiegspunkt der Anwendung und steuert die Routing-Logik.
  Sie rendern den Header, das Hauptinhalt (Login oder Balance) und den Footer.
*/
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginComponent from './components/Login/Login'; // Import der Login-Komponente
import BalanceComponent from './components/Balance/Balance'; // Import der Balance-Komponente
import HeaderComponent from './components/Header/Header'; // Import der Header-Komponente
import FooterComponent from './components/Footer/Footer'; // Import der Footer-Komponente
import ExpiredComponent from './components/Status/Expired'; // Import der Expired-Komponente
import PendingComponent from './components/Status/Pending'; // Import der Pending-Komponente

import moment from 'moment'; // Import von Moment.js

import './App.scss';
import { getData } from './services/api';

const App = () => {
  // State-Variablen für den Anmeldestatus, Ablaufstatus, Zugangscode und Daten
  const [loggedIn, setLoggedIn] = useState(false);
  const [expired, setExpired] = useState(false);
  const [status, setStatus] = useState(false);
  const [code, setCode] = useState(false);
  const [data, setData] = useState([]);
  const [ready, setReady] = useState(false);
  const [expireDate, setExpireDate] = useState('');
  const [pendingDate, setPendingDate] = useState('');

  // Funktion: Behandlung des erfolgreichen Logins
  const handleLoginSuccess = (code, data) => {
    setLoggedIn(true);
    setCode(code);
    setData(data);
    setExpired(false);
  };

  useEffect(() => {
    const checkExpireDate = async () => {
      const response = await getData('getExpireDate');

      const timeNow = moment().unix();
      const timeEnd = response.endTime === null ? null : moment.unix(response.endTime / 1000);
      const timeStart = response.startTime === null ? null : moment.unix(response.startTime / 1000);

      if (moment(timeNow).isBetween(timeStart, timeEnd)) {
        console.log('Survey is active');
        setExpired(false);
        setStatus(false);
      } else if (timeStart !== null && moment(timeNow).isBefore(timeStart)) {
        console.log('Survey is pending');
        setExpired(false);
        setPendingDate(timeStart);
        setStatus(true);
      } else if (timeEnd !== null && moment(timeNow).isAfter(timeEnd)) {
        console.log('Survey is expired');
        setExpired(true);
        setExpireDate(timeEnd);
        setStatus(true);
      }
      setReady(true);
    };

    checkExpireDate();
  }, []);

  // Rückgabe der JSX-Komponente
  return (
    <div className='wrapper'>
      <HeaderComponent />

      {ready && (
        <Router basename='/'>
          <Routes>
            {/* Route für die Startseite */}
            <Route exact path='/' element={status ? expired ? <ExpiredComponent expireDate={expireDate} /> : <PendingComponent pendingDate={pendingDate} /> : loggedIn ? <Navigate to='/balance' /> : <LoginComponent onLoginSuccess={handleLoginSuccess} />} />
            {/* Route für die Balance-Seite */}
            <Route path='/balance' element={loggedIn ? <BalanceComponent data={{ code: code, data: data }} /> : <Navigate to='/' />} />
          </Routes>
        </Router>
      )}
      <FooterComponent />
    </div>
  );
};

export default App;
