import React from 'react';
import './ChartMobileLegend.scss';
import { surveyJSON } from '../../data/data';

const data = surveyJSON;

const matrixTitles = data.pages.flatMap((page) => page.questions.filter((question) => question.type === 'matrix').map((question) => question.title));

console.log(matrixTitles);

const ChartMobileLegend = () => {
  return <div className='ChartMobileLegend'>{data.pages.flatMap((page) => page.questions.filter((question) => question.type === 'matrix').map((question) => <p key={question.title}>_{question.title} </p>))}</div>;
};

export default ChartMobileLegend;
