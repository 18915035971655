/*
  Die folgenden Funktionen und Variablen dienen zur Verwaltung und Handhabung von Datenübertragung
  und Kommunikation mit dem Server.
*/

// Überprüfen, ob der lokale Server verwendet werden soll
const useLocalServer = (process.env.REACT_APP_USE_LOCAL_SERVER || '') === 'true';

// Funktion zum Festlegen des Anfragekörpers
const setRequestBody = (param, data) => {
  let requestBody;

  if (useLocalServer) {
    requestBody = JSON.stringify({ data }); // Bei Verwendung des lokalen Servers wird der Anfragekörper als JSON formatiert
  } else {
    let csrfToken = '';
    const metaTag = document.querySelector('[name=csrf-token]');
    if (metaTag && metaTag.hasAttribute('content')) {
      csrfToken = metaTag.getAttribute('content');
    }

    requestBody = new FormData(); // Bei Verwendung des Remote-Servers wird der Anfragekörper als FormData-Objekt erstellt
    requestBody.set(param, data);
    if (csrfToken) {
      requestBody.set('_token', csrfToken);
    }
  }

  return requestBody;
};

// Funktion zum Festlegen der Anforderungsheader
const setHeaders = () => {
  let headers;
  if (useLocalServer) {
    headers = {
      'Content-Type': 'application/json'
    };
  }
  return headers;
};

// Funktion zum Festlegen der URL für verschiedene Anforderungen
const getUrl = (value) => {
  let url;

  switch (value) {
    case 'password':
      url = process.env.REACT_APP_URL_CHECK_CODE; // Abrufen der URL zum Überprüfen des Passworts
      break;
    case 'sendSurvey':
      url = process.env.REACT_APP_URL_SEND_SURVEY; // Abrufen der URL zum Senden der Umfrage
      break;
    case 'sendState':
      url = process.env.REACT_APP_URL_SET_STATE; // Abrufen der URL zum Festlegen des Zustands
      break;
    case 'getState':
      url = process.env.REACT_APP_URL_GET_STATE; // Abrufen der URL zum Abrufen des Zustands
      break;
    case 'hasSession':
      url = process.env.REACT_APP_URL_GET_SESSION; // Abrufen der URL zum Überprüfen der Sitzung
      break;
    case 'getExpireDate':
      url = process.env.REACT_APP_URL_GET_EXPIRE_DATE; // Abrufen der URL zum Überprüfen der Sitzung
      break;
    default:
      url = '';
  }
  return url;
};

// Funktion zum Senden von Daten an den Server
export async function sendData(param, data, url) {
  let responseData = {
    success: false,
    message: ''
  };

  console.log('Param: ' + param, 'Data: ' + data, 'URL: ' + url);

  try {
    const loginUrl = getUrl(url); // Abrufen der Ziel-URL
    console.log(loginUrl);
    const requestBody = setRequestBody(param, data); // Festlegen des Anfragekörpers
    let headers = setHeaders(); // Festlegen der Anforderungsheader

    const response = await fetch(loginUrl, {
      method: 'POST',
      headers,
      credentials: 'include',
      body: requestBody // Senden der Anfrage
    });

    if (response.ok) {
      if (useLocalServer) {
        responseData.success = true;
      } else {
        const respData = await response.json(); // Konvertieren der Antwort in JSON

        if (respData.ok) {
          responseData.success = true;
        } else {
          if (respData.errorText) responseData.message = respData.errorText; // Behandeln von Fehlern in der Antwort
        }
      }
    } else {
      responseData.message = 'Ein Fehler ist aufgetreten. Bitte versuche es erneut.'; // Behandeln von HTTP-Fehlern
    }
  } catch (error) {
    responseData.message = 'Ein Fehler ist aufgetreten. Bitte versuche es erneut.'; // Behandeln von Ausnahmen
  }

  return responseData; // Rückgabe der Antwortdaten
}

// Funktion zum Empfangen von Daten vom Server
export async function getData(url) {
  let responseData = {
    success: false,
    message: '',
    data: [],
    startTime: null,
    endTime: null
  };

  console.log('URL: ' + url);

  try {
    const loginUrl = getUrl(url); // Abrufen der Ziel-URL
    console.log(loginUrl);
    let headers = setHeaders(); // Festlegen der Anforderungsheader

    const response = await fetch(loginUrl, {
      method: 'GET',
      credentials: 'include',
      headers
    });

    if (response.ok) {
      if (useLocalServer) {
        responseData.success = true;
        console.log(response.surveyData);
        if (response.surveyData) responseData.data = response.surveyData; // Extrahieren von Daten aus der Antwort
      } else {
        const respData = await response.json(); // Konvertieren der Antwort in JSON

        console.log(respData);

        if (respData.ok) {
          responseData.success = true;
          if (respData.surveyData) responseData.data = respData.surveyData; // Extrahieren von Daten aus der Antwort
          if (respData.data) responseData.data = respData.data;
        } else {
          if (respData.errorText) responseData.message = respData.errorText; // Behandeln von Fehlern in der Antwort
        }

        if (respData.survey_begin) responseData.startTime = respData.survey_begin;
        if (respData.survey_end) responseData.endTime = respData.survey_end;
      }
    } else {
      responseData.message = 'Ein Fehler ist aufgetreten. Bitte versuche es erneut.'; // Behandeln von HTTP-Fehlern
    }
  } catch (error) {
    responseData.message = 'Ein Fehler ist aufgetreten. Bitte versuche es erneut.'; // Behandeln von Ausnahmen
  }

  return responseData; // Rückgabe der Antwortdaten
}
