/*
  Diese Komponente implementiert das Login-Formular für den AMBION Balance-Test.
  Sie ermöglicht Benutzern das Einloggen mithilfe eines sechsstelligen Zugangscodes.
  Die Komponente überprüft, ob eine Sitzung besteht, und ruft entsprechende Funktionen auf,
  um den Benutzer einzuloggen oder Fehlermeldungen anzuzeigen.
*/
import './Login.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Importieren der useLocation-Hook
import brand from '../../assets/images/brand.svg'; // Importieren des brand-Objekts
import { getData, sendData } from '../../services/api'; // Importieren der getData- und sendData-Funktionen

const LoginComponent = ({ onLoginSuccess }) => {
  // State-Variablen für das Passwort, Fehlermeldung, Zugangscode, Button-Deaktivierung und Sitzungszustand
  const [password, setPassword] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const location = useLocation();

  console.log('LoginComponent', location);

  // Ref für die Code-Eingabefelder
  const inputRefs = useRef([]);

  // Funktion: Behandlung des Login-Vorgangs
  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();

      const response = await sendData('password', password, 'password');
      if (response.success) {
        onLoginSuccess(password);
      } else {
        setSubmitError(response.message);
      }
    },
    [onLoginSuccess, password, setSubmitError]
  );

  // Effekt: Event-Listener für die Enter-Taste beim Laden der Komponente
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleLogin(event);
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [handleLogin]);

  // Funktion: Validierung des Logins
  const validateLogin = (pass) => {
    if (pass.length === 6) {
      setDisableSubmit(false);
      if (inputRefs.current[0]) inputRefs.current[0].blur();
    } else {
      setDisableSubmit(true);
    }
  };

  // Funktion: Behandlung der Code-Eingabe
  const handleInputChange = (index, event) => {
    const newCode = [...code];
    newCode[index] = event.target.value.toUpperCase().slice(-1);
    setCode(newCode);

    let str = '';
    newCode.forEach((char, index) => {
      str += char;
    });

    setPassword(str);

    if (event.target.value !== '' && index < code.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    if (index === code.length - 1 && event.target.value !== '') {
      inputRefs.current[index].blur();
    }

    validateLogin(str);
  };

  // Funktion: Behandlung des Einfügens von Text
  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text').toUpperCase();
    const newCode = pastedText.slice(0, 6).split('');
    setCode(newCode);

    let str = '';

    newCode.forEach((char, index) => {
      str += char;
    });

    setPassword(str);

    if (inputRefs.current.length > 0) {
      inputRefs.current[0].focus();
    }

    validateLogin(str);
  };

  // Funktion: Behandlung des Parametercodes
  const handleParameterCode = useCallback((code) => {
    const pastedText = code.toUpperCase();
    const newCode = pastedText.slice(0, 6).split('');
    setCode(newCode);

    let str = '';

    newCode.forEach((char, index) => {
      str += char;
    });

    setPassword(str);

    if (inputRefs.current.length > 0) {
      inputRefs.current[0].focus();
    }

    validateLogin(str);
  }, []);

  // Effekt: Verarbeitung des URL-Parameters 'code' beim Laden der Komponente
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    if (code) {
      handleParameterCode(code);
    }
  }, [handleParameterCode, location.search]);

  // Effekt: Überprüfung des Sitzungszustands beim Laden der Komponente
  useEffect(() => {
    const checkHasSession = async () => {
      const response = await getData('hasSession');
      console.log(response);
      if (response.success) {
        handleParameterCode(response.data);
      }
    };

    checkHasSession();
  }, [handleParameterCode]);

  // Rückgabe der JSX-Komponente
  return (
    <div id='login' className='login'>
      <div className='container'>
        <div className='inner-container'>
          <img className='logo' alt='AMBION Balance Test' src={brand} />

          <form onSubmit={handleLogin}>
            <div>
              <p className='title'>Hallo beim AMBION Balance-Test!</p>
              <p>Bitte gib hier den sechsstelligen Zugangscode ein, der Dir als E-Mail zugesendet wurde.</p>
              <div className='code'>
                {code.map((char, index) => (
                  <input
                    id={`char-${index}`}
                    key={index}
                    type='text'
                    maxLength={1}
                    value={char}
                    ref={(el) => (inputRefs.current[index] = el)} // Speichern der Refs in inputRefs
                    onChange={(e) => handleInputChange(index, e)}
                    onPaste={handlePaste}
                  />
                ))}
              </div>
            </div>
            <button disabled={disableSubmit} type='submit'>
              ABSENDEN
            </button>
            {submitError && <p className='error'>{submitError}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
