/*
  Diese Datei enthält die Implementierung des ChartComponents, das eine Radar-Chart-Ansicht der Umfrageergebnisse bereitstellt.
*/

import '../../scss/_global.scss'; // Import der globalen Styles
import './Chart.scss'; // Import der Chart-Stile
import React, { useEffect, useRef, useState } from 'react'; // Import von React und seinen Hook-Funktionen
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js'; // Import der Chart.js-Elemente
import { Radar } from 'react-chartjs-2'; // Import der Radar-Komponente aus react-chartjs-2
import { useReactToPrint } from 'react-to-print'; // Import der useReactToPrint-Hook-Funktion
import ChartHeaderComponent from './ChartHeader'; // Import der ChartHeaderComponent-Komponente
import logo from '../../assets/images/ambion_logo.png'; // Import des AMBION-Logos
import ChartDescriptionComponent from './ChartDescription'; // Import der ChartDescriptionComponent-Komponente
import { sendData } from '../../services/api'; // Import der sendData-Funktion aus dem API-Dienst
import ChartMobileLegend from './ChartMobileLegend';

// Registrierung der Chart.js-Elemente für die Verwendung in der Chart-Komponente
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

// Definition der ChartComponent-Funktionskomponente
const ChartComponent = ({ data, onCompleteChart }) => {
  const [overlayIsOpen, setOverlayIsOpen] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const chartRef = useRef(null);
  const radarChartRef = useRef(null);
  const refChart = useRef(null);
  const [isMobilePrint, setIsMobilePrint] = useState(false);
  const [ready, setReady] = useState(false);

  window.scrollTo({ top: 0, behavior: 'smooth' });

  // Funktion zum Abrufen von CSS-Variablen
  const cssvar = (name) => {
    return getComputedStyle(document.documentElement).getPropertyValue(name);
  };

  // Definition der Farben, Labels, Punktstile und Striche für die Chart-Datensätze
  const chartColors = [cssvar('--chart-color-1'), cssvar('--chart-color-2'), cssvar('--chart-color-3'), cssvar('--chart-color-4'), cssvar('--chart-color-5'), cssvar('--chart-color-6'), cssvar('--chart-color-7'), cssvar('--chart-color-8'), cssvar('--chart-color-9'), cssvar('--chart-color-10')];
  const labels = ['REAL', 'WUNSCH'];
  const pointStyles = ['rect', 'circle'];
  const strokes = [
    [5, 5],
    [0, 0]
  ];

  // Effekt-Hook zum Zerstören der Chart-Instanz bei Änderungen der Daten
  useEffect(() => {
    if (chartRef && chartRef.current && chartRef.current.chartInstance) {
      chartRef.current.chartInstance.destroy();
    }
  }, []);

  useEffect(() => {
    saveChartData(chartData);
  }, []);

  // Funktion zum Drucken des Charts
  const printComponent = () => {
    setTimeout(() => {
      refChart.current.config.options.scales.r.pointLabels.display = true;
      refChart.current.update();
    }, 500);

    window.innerWidth < 768 ? setIsMobilePrint(true) : setIsMobilePrint(false);

    setTimeout(() => {
      print();
    }, 1000);
  };

  const print = useReactToPrint({
    onAfterPrint: function () {
      if (window.innerWidth < 768) {
        refChart.current.config.options.scales.r.pointLabels.display = false;
        setIsMobilePrint(false);
        refChart.current.update();
      }
    },
    content: () => radarChartRef.current,
    copyStyles: true,
    documentTitle: 'AMBION Balance-Test 2024'
  });

  // Funktion zur Verarbeitung der Umfrageergebnisdaten
  const processSurveyData = (surveyData) => {
    const allLabels = [];
    const displayedLabels = [];
    const dataSet1 = [];
    const dataSet2 = [];

    surveyData.forEach((response, index) => {
      const responseKeys = Object.keys(response);

      responseKeys.forEach((key, index) => {
        if (!allLabels.includes(key)) {
          allLabels.push(key);
          displayedLabels.push(key);
        }
      });
    });

    allLabels.forEach((label, index) => {
      surveyData.forEach((response) => {
        const values = response[label];
        let subData1 = [];
        let subData2 = [];

        Object.keys(values).map(function (keyName, keyIndex) {
          if (keyIndex % 2 === 0) {
            subData1.push(values[keyName]);
          } else {
            subData2.push(values[keyName]);
          }

          return null;
        });

        const getCount = (value) => {
          let countNum = 0;

          value.forEach((val) => {
            countNum += val;
          });

          return countNum;
        };

        dataSet1.push((getCount(subData1) / subData1.length).toFixed(1));
        dataSet2.push((getCount(subData2) / subData2.length).toFixed(1));
      });
    });

    return {
      displayedLabels: displayedLabels,
      dataSet1: dataSet1,
      dataSet2: dataSet2
    };
  };

  // Rückgabe, wenn keine Daten vorhanden sind
  if (!data[0] || data[0].length === 0) {
    return <div>No data available</div>;
  }

  // Verarbeitung der Umfrageergebnisdaten
  const processedData = processSurveyData(data[0]);
  if (!processedData || !processedData.displayedLabels || processedData.displayedLabels.length === 0) {
    return <div>No valid data available for chart</div>;
  }

  // Funktion zum Anzeigen/Verbergen des Overlay-Infos
  const handleInfoOverlay = () => {
    setOverlayIsOpen(!overlayIsOpen);
  };

  // Bestimmung der Chart-Datensätze
  const saveChartData = async (data) => {
    const response = await sendData('chartData', JSON.stringify(data), 'sendSurvey');
    setReady(true);
    if (response.success) {
    } else {
      setSubmitError(response.message);
    }
  };

  // Chart-Daten für die Radar-Chart
  const chartDataSet = () => {
    let data = [];

    labels.forEach((label, index) => {
      const set = {
        label: label,
        data: processedData[`dataSet${index + 1}`],
        backgroundColor: `rgba(${chartColors[index + 1]},0.2)`,
        borderColor: `rgba(${chartColors[index]}, 1)`,
        borderDash: strokes[index],
        pointBackgroundColor: `rgba(${chartColors[index]}, 1)`,
        pointBorderColor: `rgba(${chartColors[index]}, 1)`,
        pointHoverBackgroundColor: `rgba(${chartColors[index]}, 1)`,
        pointHoverBorderColor: `rgba(${chartColors[index]}, 1)`,
        borderWidth: 2,
        pointStyle: pointStyles[index],
        pointBorderWidth: 2,
        radius: 6,
        fill: 1
      };

      data.push(set);
    });

    return data;
  };

  // Speichern der Chart-Daten

  const changeLabelForMobile = () => {
    if (window.innerWidth < 768) {
      processedData.displayedLabels.forEach((label, index) => {
        processedData.displayedLabels[index] = label.substring(0, 1);
      });
    }

    return processedData.displayedLabels;
  };

  const chartData = {
    labels: ready ? changeLabelForMobile() : processedData.displayedLabels,
    datasets: chartDataSet()
  };

  // Chart-Optionen
  const chartOptions = {
    aspectRatio: 1,
    onResize: () => {
      if (refChart.current) {
        refChart.current.config.options.scales.r.pointLabels.display = window.innerWidth < 768 ? false : true;
        refChart.current.update();
      }
    },
    animations: {
      radius: {
        duration: 1400,
        easing: 'linear',
        loop: (context) => context.active
      }
    },
    plugins: {
      tooltip: {
        enabled: true,
        padding: 20,
        cornerRadius: 0,
        boxPadding: 10,
        usePointStyle: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'rgba(0, 0, 0, 1)',
        borderWidth: 1,
        titleColor: 'rgba(0, 0, 0, 1)',
        bodyColor: 'rgba(0, 0, 0, 1)',
        titleMarginBottom: 14
      },
      filler: {
        propagate: true
      },

      legend: {
        display: false,
        position: 'top', // 'top', 'bottom
        align: 'center',
        maxWidth: 1000,
        maxHeight: 600,
        labels: {
          borderRadius: 80,
          usePointStyle: true,

          padding: 20,
          color: '#000000',
          font: {
            size: 14,
            family: 'DIN_regular'
          }
        }
      }
    },
    scales: {
      r: {
        grid: {
          color: 'rgba(0, 0, 0, .1)'
        },
        angleLines: {
          color: 'rgba(0, 0, 0, .1)'
        },
        pointLabels: {
          display: window.innerWidth < 768 ? false : true,
          color: '#000000',
          font: {
            size: 10,
            family: 'DIN_regular'
          }
        },

        min: 0,
        max: 5,
        ticks: {
          stepSize: 0.5,
          color: 'rgba(0, 0, 0, .5)',
          backdropColor: 'rgba(0, 0, 0, 0)',
          font: {
            size: 10,
            family: 'DIN_regular'
          }
        }
      }
    }
  };

  // Stil für die Chart-Komponente
  const chartStyle = {
    maxWidth: 1000,
    maxHeight: 700
  };

  return (
    <div className='chart'>
      <div className='printable-content' ref={radarChartRef}>
        <div className='print-logo'>
          <img alt='AMBION Logo' src={logo} />
        </div>

        <div className='canvas-container'>
          <ChartHeaderComponent onPrintChart={printComponent} onOpenOverlay={handleInfoOverlay} />
          {ready && <Radar data={chartData} style={chartStyle} options={chartOptions} ref={refChart} />}
        </div>

        <div className='print-info'>
          {isMobilePrint && <ChartMobileLegend />}
          <ChartDescriptionComponent />
        </div>
      </div>

      <div className={overlayIsOpen ? 'info-container visible' : 'info-container'}>
        <div className='info-background' onClick={handleInfoOverlay} />
        <div className='info-content'>
          <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={handleInfoOverlay}>
            <span aria-hidden='true'>&times;</span>
          </button>
          <ChartDescriptionComponent />
        </div>
      </div>
    </div>
  );
};

export default ChartComponent;
