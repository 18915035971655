/*
  Diese Komponente stellt eine feste Tabelle dar, die während des Scrollens fixiert wird.
  Sie steuert die Anzeige der Tabelle basierend auf der Scrollposition und ändert die Daten entsprechend.
*/
import './FixedTable.scss';
import React, { useEffect, useState } from 'react';

const FixedTable = ({ data, hide }) => {
  // Zustandsvariable für die Daten der festen Tabelle
  const [fixedTableData, setFixedTableData] = useState([]);

  // Effekt: Änderungen an den Daten oder der Sichtbarkeit der Tabelle
  useEffect(() => {
    document.addEventListener('scroll', (event) => {
      checkFixedTablePositionAndShowOrHide(window.scrollY);
    });

    // Timeout zum Anzeigen der Tabelle
    const fixedTableElement = document.getElementById('fixedTable');
    fixedTableElement.classList.remove('visible');
    setTimeout(() => {
      const fixedTableElement = document.getElementById('fixedTable');
      if (fixedTableElement) {
        fixedTableElement.classList.add('visible');
      }
    }, 2000);

    changeFixedTableData(data);
  }, [data, hide]);

  // Funktion: Überprüfung der Position der festen Tabelle und Anzeige oder Ausblenden entsprechend
  const checkFixedTablePositionAndShowOrHide = (positionY) => {
    const table = document.querySelector('.sd-matrix');
    const fixedTableElement = document.getElementById('fixedTable');
    if (table) {
      const tablePositionY = table.getBoundingClientRect().top;
      if (fixedTableElement) {
        if (tablePositionY < 0) {
          fixedTableElement.classList.add('show');
        } else {
          fixedTableElement.classList.remove('show');
        }
      }
    }
  };

  // Funktion: Änderung der Daten der festen Tabelle
  const changeFixedTableData = (data) => {
    const fixedTableElement = document.getElementById('fixedTable');
    if (fixedTableElement) {
      fixedTableElement.classList.remove('visible');
    }

    const tableData = [];
    if (data.columns) {
      tableData.push(data.name);
      data.columns.forEach((element) => {
        tableData.push(element.text);
      });
      setFixedTableData(tableData);
    }
  };

  // Rückgabe der JSX-Komponente
  return (
    <div id='fixedTable'>
      {!hide && (
        <div className='content'>
          <div className='table-content'>
            <table>
              <thead>
                <tr>
                  {fixedTableData.map(function (object, i) {
                    return i === 0 ? (
                      <td key={i}>
                        <span>{object}</span>
                      </td>
                    ) : (
                      <th key={i}>
                        <span>{object}</span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default FixedTable;
